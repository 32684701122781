import { axiosInstance } from "../../api/axiosinstance";
import config from "../../api/config.json";

export const GeneralService = {
  getAll: async (url, payload) => {
    let responseData = [];

    await axiosInstance
      .post(url, payload)
      .then((res) => {
        responseData = res.data;
      })
      .catch((err) => {
        console.log("ERROR", err);
        throw err;
      });
    return responseData;
  },
  getUrl: async (url, payload) => {
    let responseData = [];
    await axiosInstance
      .get(url, payload)
      .then((res) => {
        responseData = res.data;
      })
      .catch((err) => {
        console.log("ERROR", err);
        throw err;
      });

    return responseData;
    },
    getRevocations: async () => {
        try {
            const response = await axiosInstance.get(config[3].URL);
            return response.data;
        } catch (err) {
            console.error("Error fetching revocations:", err);
            throw err;
        }
    },
};
