import React, { useState, useEffect } from "react";
import PageTitle from "../../components/PageTitle";
import { Box, CardMedia, Container, Grid, Typography } from "@mui/material";
import styles from "./activity.module.scss";
import { useTranslation } from "react-i18next";
import { GeneralService } from "../../services";
import { Helmet } from "react-helmet";

export default function Activitys() {
  const { t, i18n } = useTranslation();

  const [activitys, setactivitys] = useState([]);

  useEffect(() => {
    GeneralService.getAll("/GetAll", {
      Type: 1,
      Language: i18n.language === "tr" ? 1 : 0,
    })
      .then((data) => {
        const datalist = data.DataList;
        const sortedActivitys = datalist.sort(
          (a, b) => a.rowNumber - b.rowNumber
        );

        setactivitys(sortedActivitys);
      })
      .catch((err) => {
        console.log("Error", err);
        throw err;
      });
  }, [i18n.language]);

  return (
    <>
      <Helmet>
        <title>{t("activitys.title")}</title>
        <meta name="description" content={t("activitys.title")} />
      </Helmet>
      <PageTitle Title={t("activity")} />
      <Container className={styles.activitys}>
        <Grid
          columns={12}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          className={styles.imageList}
        >
          <Grid item md={4} className={styles.eventsImage}>
            <CardMedia
              component="img"
              image={"/document/images/activity/amsterdam.png"}
            />
            <Typography variant="span">Transit Ticketing</Typography>
          </Grid>
          <Grid item md={4} className={styles.eventsImage}>
            <CardMedia
              component="img"
              image={"/document/images/activity/transit.png"}
            />
            <Typography variant="span">MPE Berlin</Typography>
          </Grid>
          <Grid item md={4} className={styles.eventsImage}>
            <CardMedia
              component="img"
              image={"/document/images/activity/money2020.png"}
            />
            <Typography variant="span">Money 20/20 Las Vegas</Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Box md={12} className={styles.titleTab}>
            <Typography variant="h3">{t("activitys.title2")}</Typography>
          </Box>
        </Grid>
        <Box sx={{ flexGrow: 1 }} className={styles.table}>
          {activitys &&
            activitys.map((item, key) => {
              const textData = JSON.parse(item.Text);
              const { Date, Title, Content } = textData;
              const rowClass = key % 2 === 0 ? styles.light : styles.dark;

              return (
                <>
                  <Grid
                    key={key}
                    container
                    className={`${styles.tableRow} ${rowClass}`}
                  >
                    <Grid item xs={8}>
                      <Typography variant="span">{Title}</Typography>
                      <div
                        className={styles.tableDetail}
                        dangerouslySetInnerHTML={{
                          __html: Content,
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} textAlign={"right"}>
                      <Typography variant="strong">{Date}</Typography>
                    </Grid>
                  </Grid>
                </>
              );
            })}
        </Box>
      </Container>
    </>
  );
}
