import {
  Container,
  Grid,
  Box,
  Typography,
  CardMedia,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./certificate.module.scss";
import { useTranslation } from "react-i18next";

function Certificate() {
  const { t, i18n } = useTranslation();

  const [language, setLanguage] = useState(i18n.language);
  // Dil değişikliği yapıldığında bileşeni yeniden render et
  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);
  return (
    <>
      <Grid columnSpacing={{ md: 12 }} className={styles.certificateHome}>
        <Container>
          <Typography variant="h2">{t("certificates.title")}</Typography>

          <Box
            sx={{ flexGrow: 1 }}
            textAlign="center"
            className={styles.certificateMargin}
          >
            <Grid
              direction="row"
              justifyContent="center"
              alignItems="center"
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid xs={2} sm={4} md={2} className={styles.certificateList}>
                <Box className={styles.certificateImg} columns={{ md: 12 }}>
                  <CardMedia
                    component="img"
                    image="document/images/logo/bkm.png"
                    alt="BKM"
                  />
                </Box>
              </Grid>
              <Grid xs={2} sm={4} md={2} className={styles.certificateList}>
                <Box className={styles.certificateImg} columns={{ md: 12 }}>
                  <CardMedia
                    component="img"
                    image="document/images/logo/visa.svg"
                    alt="VISA"
                  />
                </Box>
              </Grid>

              <Grid xs={2} sm={4} md={2} className={styles.certificateList}>
                <Box className={styles.certificateImg} columns={{ md: 12 }}>
                  <CardMedia
                    component="img"
                    image="document/images/logo/master.svg"
                    alt="MASTERCARD"
                  />
                </Box>
              </Grid>

              <Grid xs={2} sm={4} md={2} className={styles.certificateList}>
                <Box className={styles.certificateImg} columns={{ md: 12 }}>
                  <CardMedia
                    component="img"
                    image="document/images/logo/pci.svg"
                    alt="PCI"
                  />
                </Box>
              </Grid>

              <Grid xs={2} sm={4} md={2} className={styles.certificateList}>
                <Box className={styles.certificateImg} columns={{ md: 12 }}>
                  <CardMedia
                    component="img"
                    image="document/images/logo/troy.svg"
                    alt="troy"
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className={styles.certificateText}>
            <Grid
              direction="row"
              justifyContent="center"
              alignItems="center"
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid
                xs={12}
                sm={6}
                md={5}
                margin={"2rem"}
                className={styles.textGrid}
              >
                <Typography variant="h5" gutterBottom>
                  {t("certificates.cardTitle")}
                </Typography>
                <Typography>{t("certificates.cardDesc")}</Typography>
              </Grid>

              <Grid
                xs={12}
                sm={6}
                md={5}
                margin={"2rem"}
                className={styles.textGrid}
              >
                <Typography variant="h5" gutterBottom>
                  {t("certificates.troy")}
                </Typography>
                <Typography>{t("certificates.troyDesc")}</Typography>
              </Grid>
            </Grid>
          </Box>

          <Box className={styles.certificateText}>
            <Grid
              direction="row"
              justifyContent="center"
              alignItems="center"
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid
                xs={12}
                sm={6}
                md={5}
                margin={"2rem"}
                className={styles.textGrid}
              >
                <Typography variant="h5" gutterBottom>
                  {t("certificates.communityCloud")}
                </Typography>
                <Typography>{t("certificates.communityCloudDesc")}</Typography>
              </Grid>

              <Grid
                xs={12}
                sm={6}
                md={5}
                margin={"2rem"}
                className={styles.textGrid}
              >
                <Typography variant="h5" gutterBottom>
                  {t("certificates.e-money")}
                </Typography>
                <Typography>{t("certificates.e-moneyDesc")}</Typography>
              </Grid>
            </Grid>
          </Box>

          <Box className={styles.certificateText}>
            <Grid
              direction="row"
              justifyContent="center"
              alignItems="center"
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid xs={12} sm={12} md={12}>
                <Typography>{t("certificates.desc")}</Typography>
              </Grid>
            </Grid>
          </Box>
          <Box
            className={language === "en" ? styles.none : styles.certificateText}
          >
            <Button
              variant="text"
              href={t("certificates.policyDoc")}
              className="btnText"
            >
              {t("certificates.policy")}
            </Button>
          </Box>
        </Container>
      </Grid>
    </>
  );
}

export default Certificate;
