import { Grid, Box, CardMedia, Typography } from "@mui/material";
import React from "react";
import styles from "./pageContent.module.scss";
import { Helmet } from "react-helmet";
import config from "../../api/config.json";

export default function PageContent(props) {
  return (
    <>
      <Helmet>
        <title>{props.SeoTitle}</title>
        <meta name="description" content={props.SeoContent} />
      </Helmet>

      <Grid
        spacing={{ xs: 0, sm: 1, md: 2 }}
        columns={12}
        className={styles.pageContent}
      >
        <Box mt={5} className={styles.pageBox}>
          {props.Image && (
            <CardMedia
              component="img"
              image={config[0].URL + props.Image}
              title={props.Title}
            />
          )}
          <Typography variant="h1"> {props.Title}</Typography>

          <div dangerouslySetInnerHTML={{ __html: props.Content }} />
        </Box>
      </Grid>
    </>
  );
}
