import * as React from "react";
import { Container, Grid, Box, CardMedia } from "@mui/material";
import dgpaysLogo from "../../static/document/images/dgpays-logo.svg";
import Navbar from "../../static/navbar/index";
import styles from "./header.module.scss";
import { Link } from "react-router-dom";
import { t } from "i18next";

export default function Header() {
  return (
    <Box
      className={styles.headerFixed}
      sx={{ boxShadow: 3, zIndex: 999, position: "relative" }}
    >
      <Container maxWidth="lg">
        <Box className={styles.header}>
          <Grid container columns={{ xs: 12, sm: 8, md: 12 }}>
            <Grid item xs={5} md={2} className={styles.logo}>
              <Link to={t("routes.home")}>
                <CardMedia
                  component="img"
                  image={dgpaysLogo}
                  alt="DGPAYS Logo"
                />
              </Link>
            </Grid>
            <Navbar />
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
