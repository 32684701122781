import React, { useState, useEffect } from "react";
import PageTitle from "../../components/PageTitle";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import styles from "./news.module.scss";
import { useTranslation } from "react-i18next";
import { GeneralService } from "../../services";
import { Link } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import config from "../../api/config.json";
import { Helmet } from "react-helmet";

export default function News() {
  const { t, i18n } = useTranslation();
  const [news, setnews] = useState([]);

  useEffect(() => {
    GeneralService.getAll("/GetAll", {
      Type: 0,
      Language: i18n.language === "tr" ? 1 : 0,
    })
      .then((data) => {
        const datalist = data.DataList;
        datalist.sort((a, b) => {
          const rowNumberA = JSON.parse(a.Text).RowNumber;
          const rowNumberB = JSON.parse(b.Text).RowNumber;
          return rowNumberB - rowNumberA;
        });
        setnews(datalist);
      })
      .catch((err) => {
        console.log("Error", err);
        throw err;
      });
  }, [i18n.language]);

  return (
    <>
      <PageTitle Title={t("news")} />
      <Container className={styles.news}>
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="center"
          columns={12}
          className={styles.news}
        >
          {news &&
            news.map((item, key) => {
              const textData = JSON.parse(item.Text);
              const { SeoUrl, Title, Date, Content, Image } = textData;

              return (
                <Grid
                  xs={12}
                  sm={4}
                  md={3}
                  className={styles.newList}
                  key={key}
                >
                  <Helmet>
                    <title>{t("newsPage.Title")}</title>
                    <meta name="description" content={t("newsPage.Desc")} />
                  </Helmet>

                  <Card className={styles.newsCard}>
                    <Link to={`/${t("routes.news")}/${SeoUrl}`} key={key}>
                      <Card columns={{ md: 12 }}>
                        <CardMedia
                          sx={{ height: 210 }}
                          image={config[0].URL + `${Image}`}
                          title={Title}
                        />
                        <CardContent className={styles.cardContent}>
                          <Typography gutterBottom variant="caption">
                            {Date}
                          </Typography>
                          <Typography gutterBottom variant="h5">
                            {Title}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: Content,
                              }}
                            />
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <IconButton
                            className={styles.iconButton}
                            component="label"
                          >
                            <EastIcon />
                          </IconButton>
                        </CardActions>
                      </Card>
                    </Link>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      </Container>
    </>
  );
}
