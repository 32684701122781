import {
  Container,
  Grid,
  Typography,
  CardMedia,
  Card,
  CardContent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./awards.module.scss";
import { useTranslation } from "react-i18next";
import { GeneralService } from "../../services";
import config from "../../api/config.json";

export default function Awards() {
  const { t, i18n } = useTranslation();
  const [awards, setawards] = useState([]);

  useEffect(() => {
    GeneralService.getAll("/GetAll", {
      Type: 3,
      Language: i18n.language === "tr" ? 1 : 0,
    })
      .then((data) => {
        const datalist = data.DataList;
        setawards(datalist);
      })
      .catch((err) => {
        throw err;
      });
  }, [i18n.language]);
  return (
    <>
      <Container className={styles.awards}>
        <Typography textAlign={"center"} mt={5} mb={5} variant="h2">
          {t("awards")}
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          columns={12}
        >
          {awards &&
            awards.map((items) => {
              const textData = JSON.parse(items.Text);
              const { Title, Image } = textData;

              return (
                <Grid xs={6} sm={6} md={4} textAlign={"center"} key={1}>
                  <Card variant="outlined" className={styles.card}>
                    <CardMedia
                      component="img"
                      image={config[0].URL + `${Image}`}
                      alt={Title}
                    />
                    <CardContent zeroMinWidth className={styles.cardContent}>
                      <Typography variant="h6">{Title}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      </Container>
    </>
  );
}
