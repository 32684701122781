import React, { useState, useEffect } from "react";
import styles from "./teamDetail.module.scss";
import { Box, CardMedia, Container, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GeneralService } from "../../services";
import { Helmet } from "react-helmet";
import config from "../../api/config.json";

export default function TeamDetail() {
  const [teams, setteams] = useState([]);
  const { t, i18n } = useTranslation();
  const { slug } = useParams();
  useEffect(() => {
    GeneralService.getAll("/GetAll", {
      Type: 6,
      Language: i18n.language === "tr" ? 1 : 0,
    })
      .then((data) => {
        const datalist = data.DataList;
        setteams(datalist);
      })
      .catch((err) => {
        console.log("Error", err);
        throw err;
      });
  }, [i18n.language]);
  return (
    <>
      <Box className={styles.TeamDetail}>
        <Container sx={{ zIndex: 9 }}>
          {teams &&
            teams.map((items) => {
              const textData = JSON.parse(items.Text);
              const {
                SeoUrl,
                Title,
                Content,
                Image,
                Position,
                SeoTitle,
                SeoContent,
              } = textData;
              if (SeoUrl === slug) {
                return (
                  <>
                    <Helmet>
                      <title>{SeoTitle}</title>
                      <meta name="description" content={SeoContent} />
                    </Helmet>
                    <Grid columns={12} container>
                      <Grid xs={12} md={4}>
                        <CardMedia
                          component="img"
                          image={config[0].URL + `${Image}`}
                          alt={Title}
                        />
                      </Grid>
                      <Grid xs={12} md={8} className={styles.teamBiography}>
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          className={styles.teamName}
                        >
                          <Box>
                            <Typography variant="h1">{Title}</Typography>
                            <Typography variant="h2">{Position}</Typography>
                          </Box>
                        </Grid>
                        <Grid container>
                          <Box className={styles.textDetail}>
                            <Typography>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: Content,
                                }}
                              />
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                );
              }
            })}
        </Container>
      </Box>
    </>
  );
}
