import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import styles from "./representation.module.scss";
import PageTitle from "../../components/PageTitle";
import { useTranslation } from "react-i18next";
import { GeneralService } from "../../services";
import { Helmet } from "react-helmet";
import config from "../../api/config.json";

export default function Representation() {
  const { t, i18n } = useTranslation();

  const [representation, setrepresentation] = useState([]);

  useEffect(() => {
    GeneralService.getAll("/GetAll", {
      Type: 11,
      Language: i18n.language === "tr" ? 1 : 0,
    })
      .then((data) => {
        const datalist = data.DataList;

        datalist.sort((a, b) => {
          const textDataA = JSON.parse(a.Text);
          const textDataB = JSON.parse(b.Text);
          const titleA = textDataA.Title.toLowerCase();
          const titleB = textDataB.Title.toLowerCase();
          return titleA.localeCompare(titleB);
        });
        setrepresentation(datalist);
      })
      .catch((err) => {
        console.log("Error", err);
        throw err;
      });
  }, [i18n.language]);

  return (
    <>
      <PageTitle Title={t("representation")} />
      <Container className={styles.representation}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          columns={12}
        >
          {representation &&
            representation.map((items, key) => {
              const textData = JSON.parse(items.Text);
              const { Title, Image } = textData;

              return (
                <>
                  <Helmet>
                    <title>{t("representationSeoTitle")}</title>
                    <meta
                      name="description"
                      content={t("representationSeoDesc")}
                    />
                  </Helmet>
                  <Grid
                    xs={12}
                    sm={6}
                    md={4}
                    key={key}
                    textAlign={"center"}
                    className={styles.representationGrid}
                  >
                    <Card className={styles.representationCard}>
                      <CardMedia
                        component="img"
                        image={config[0].URL + `${Image}`}
                        alt={Title}
                      />
                    </Card>
                  </Grid>
                </>
              );
            })}
        </Grid>
      </Container>
    </>
  );
}
