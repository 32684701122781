import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import styles from "./shareholders.module.scss";
import PageTitle from "../../components/PageTitle";
import { useTranslation } from "react-i18next";
import { GeneralService } from "../../services";
import { Helmet } from "react-helmet";
import config from "../../api/config.json";

export default function Shareholders() {
  const { t, i18n } = useTranslation();

  const [shareholders, setshareholders] = useState([]);
  useEffect(() => {
    GeneralService.getAll("/GetAll", {
      Type: 4,
      Language: i18n.language === "tr" ? 1 : 0,
    })
      .then((data) => {
        const datalist = data.DataList;
        setshareholders(datalist);
      })
      .catch((err) => {
        console.log("Error", err);
        throw err;
      });
  }, [i18n.language]);
  return (
    <>
      <Helmet>
        <title>{t("investor.title")}</title>
        <meta name="description" content={t("investor.desc")} />
      </Helmet>
      <PageTitle Title={t("investors")} />
      <Container className={styles.affiliates}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          columns={12}
        >
          {shareholders &&
            shareholders.map((item) => {
              const textData = JSON.parse(item.Text);
              const { Title, Image } = textData;

              return (
                <>
                  <Grid
                    xs={12}
                    sm={6}
                    md={3}
                    key={item.Id}
                    textAlign={"center"}
                    className={styles.affGrid}
                  >
                    <Card className={styles.affCard}>
                      <Box className={styles.image}>
                        <CardMedia
                          component="img"
                          image={config[0].URL + `${Image}`}
                          alt={Title}
                        />
                      </Box>
                      <CardContent className={styles.affCardContent}>
                        <Typography variant="h6">{Title}</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </>
              );
            })}
        </Grid>
      </Container>
    </>
  );
}
