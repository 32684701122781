import React, { useEffect, useState } from "react";
import { Container, Grid, Box, Button, Avatar } from "@mui/material";
import styles from "./about.module.scss";
import { HomeService } from "../../../../services";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function About() {
  const [homePage, sethomePage] = useState([]);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    HomeService.getAbout("/GetAll", {
      Type: 8,
      Language: i18n.language === "tr" ? 1 : 0,
    })
      .then((data) => {
        sethomePage(data);
      })
      .catch((err) => {
        console.log("Error", err);
        throw err;
      });
  }, [i18n.language]);

  return (
    <Container>
      <Box className={styles.aboutHome}>
        {homePage.DataList &&
          homePage.DataList.map((item) => {
            const textData = JSON.parse(item.Text);
            if (textData.HomeStatus == true) {
              return (
                <>
                  <Grid container columnSpacing={{ xs: 1 }} key={item.Id}>
                    <Grid xs={12} sm={12} md={6}>
                      <h2> {textData.Title}</h2>
                      <div
                        dangerouslySetInnerHTML={{ __html: textData.Content }}
                      />
                      <Button
                        component={Link}
                        to={t("routes.about")}
                        className={styles.buttons}
                        variant="text"
                      >
                        {t("homeBtnName")}
                      </Button>
                    </Grid>
                    <Grid xs={12} sm={12} md={6}>
                      <Avatar
                        className={styles.images}
                        src="/document/images/about/homeAbout.png"
                        alt={t("about")}
                      />
                    </Grid>
                  </Grid>
                </>
              );
            }
          })}
      </Box>
    </Container>
  );
}
