import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './assets/style.css';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import locales from "../../locales";
import { getLanguage } from "../../i18n";
import { Avatar, TextField, Button, Alert } from '@mui/material';

const SoftposQuery = () => {
    const [checkSumValue, setCheckSumValue] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { slug } = useParams();
    const [data] = useState([]);

    function getKeyByValue(object, value) {
        return Object.keys(object).find((key) => object[key] === value);
    }

    useEffect(() => {
        changeLanguage(getLanguage());
        document.body.style.overflow = 'hidden';
    }, [])

    useEffect(() => {
        const fetchConfig = async () => {
            try {
                const response = await axios.get('../config.json');
                const data = response.data;
                const targetUrl = "https://preprodsoftpos.dgpays.com/cmn/api/verify/CheckSumValue";//data.find(item => item.ID === 5).URL;

                const handleClick = async () => {
                    try {
                        const res = await axios.post(targetUrl, {
                            CheckSumValue: checkSumValue
                        });

                        if (res.data.IsSuccessful) {
                            setResponseMessage('Girmiş olduğunuz değer başarılı.');
                        } else {
                            setResponseMessage('Girmiş olduğunuz değer geçersiz. Tekrar deneyin.');
                        }
                    } catch (error) {
                        setResponseMessage('Bir hata oluştu: ' + error.message);
                    }
                };

                document.getElementById('validateButton').onclick = handleClick;

            } catch (error) {
                console.error('Error fetching config:', error);
            }
        };

        fetchConfig();

        return () => {
            document.getElementById('validateButton').onclick = null;
        };
    }, [checkSumValue]);

    const handleOnChange = (e) => {
        setCheckSumValue(e.target.value.toUpperCase());
        setResponseMessage('');
    }

    const changeLanguage = (lng) => {
        const path = location.pathname.split("/").join(" ").trim().split(" ");
        const arr = path;
        i18n.language === "tr" && arr.push("tr");
        let key = getKeyByValue(
            locales[i18n.language].routes,
            path.slice(0, i18n.language === "tr" ? 1 : 2).join("/")
        );

        if (key) {
            if (arr.length >= 3) {
                const getPath = data.find((e) => e.MainUrl === slug)[
                    lng === "tr" ? "UrlTr" : "UrlEn"
                ];
                const redirect = t(`routes.${key}`, { lng: lng }) + "/" + getPath;
                navigate(redirect);
            } else {
                const redirect = t(`routes.${key}`, { lng: lng });
                navigate('/' + redirect);
            }
        } else {
            i18n.changeLanguage(lng);
        }

        localStorage.setItem("language", lng);
    };

    return (
        <div className="two-columns">
            <div className="left-side">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div><img src="../document/images/dgpays-logo.svg" alt="Logo" className="logo" /></div>
                    {i18n.language === "tr" ? (
                        <div onClick={() => changeLanguage("en")}>
                            <Avatar
                                sx={{
                                    width: "16px",
                                    height: "16px",
                                    float: "left",
                                    padding: "0px 5px",
                                }}
                                alt="English"
                                src="/document/images/usa.png"
                            />
                            English
                        </div>
                    ) : (
                        <div onClick={() => changeLanguage("tr")}>
                            <Avatar
                                sx={{
                                    width: "16px",
                                    height: "16px",
                                    float: "left",
                                    padding: "0px 5px",
                                }}
                                alt="Türkçe"
                                src="/document/images/tr.png"
                            />
                            Türkçe
                        </div>
                    )}
                </div>
                <div >
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh'
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textAlign: 'center',
                            width: '90%', 
                            maxWidth: '60%',
                            padding: '16px',
                            borderRadius: '8px' 
                        }}>
                            <h2>{t('softpos.QueryScreenText')}</h2>
                            <TextField
                                id="checkSumInput"
                                variant="outlined"
                                placeholder={t('softpos.KeyInsert')}
                                value={checkSumValue}
                                onChange={handleOnChange}
                                fullWidth
                                margin="normal"
                                style={{ height: '56px' }}
                                sx={{
                                    '& .MuiInputBase-input': {
                                        padding: '10px',
                                    },
                                }}
                            />
                            <Button
                                id="validateButton"
                                variant="contained"
                                color="primary"
                                fullWidth
                                style={{ borderRadius: '5px' }} 
                            >
                                {t('softpos.Validate')}
                            </Button>
                            <div style={{ marginTop: '15px', width: '100%' }}>
                                {responseMessage && (
                                    <Alert severity={responseMessage.includes('başarılı') ? 'success' : 'error'}>
                                        {responseMessage}
                                    </Alert>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="right-side"></div>
        </div>
    );
};

export default SoftposQuery;