import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import styles from "./affiliates.module.scss";
import PageTitle from "../../components/PageTitle";
import { useTranslation } from "react-i18next";
import { GeneralService } from "../../services";
import { Helmet } from "react-helmet";
import config from "../../api/config.json";

export default function Affiliates() {
  const { t, i18n } = useTranslation();

  const [affiliates, setaffiliates] = useState([]);

  useEffect(() => {
    GeneralService.getAll("/GetAll", {
      Type: 9,
      Language: i18n.language === "tr" ? 1 : 0,
    })
      .then((data) => {
        const datalist = data.DataList;
        setaffiliates(datalist);
      })
      .catch((err) => {
        console.log("Error", err);
        throw err;
      });
  }, [i18n.language]);
  return (
    <>
      <PageTitle Title={t("affiliates")} />
      <Container className={styles.affiliates}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          columns={12}
        >
          {affiliates &&
            affiliates.map((items, key) => {
              const textData = JSON.parse(items.Text);
              const { SeoUrl, Title, Image } = textData;

              return (
                <>
                  <Helmet>
                    <title>{t("affiliatePage.Title")}</title>
                    <meta
                      name="description"
                      content={t("affiliatePage.Desc")}
                    />
                  </Helmet>
                  <Grid
                    xs={12}
                    sm={6}
                    md={4}
                    key={items.Id}
                    textAlign={"center"}
                    className={styles.affGrid}
                  >
                    <Link
                      href={"/" + t("routes.affiliates") + "/" + SeoUrl}
                      className={styles.link}
                    >
                      <Card className={styles.affCard}>
                        <Box className={styles.image}>
                          <CardMedia
                            component="img"
                            image={config[0].URL + `${Image}`}
                            alt={Title}
                          />
                        </Box>
                        <CardContent className={styles.affCardContent}>
                          <Typography variant="h6">{Title}</Typography>
                        </CardContent>
                      </Card>
                    </Link>
                  </Grid>
                </>
              );
            })}
        </Grid>
      </Container>
    </>
  );
}
