import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import styles from "./contact.module.scss";
import { Container, Grid } from "@mui/material";
import Locations from "../../components/Locations";
import { Helmet } from "react-helmet";
import ContactForm from "../../components/ContactForm";
import { useTranslation } from "react-i18next";

export default function Contact() {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  // Dil değişikliği yapıldığında bileşeni yeniden render et
  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);
  return (
    <>
      <PageTitle Title={t("contact")} />
      <Helmet>
        <title>{t("contactPage.Title")}</title>
        <meta name="description" content={t("contactPage.Desc")} />
      </Helmet>
      <Container className={styles.contact}>
        <Locations />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          columns={12}
          key={3}
        >
          <Grid xs={12} sm={12} md={12} key={2}>
            <ContactForm />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
