import React, { useEffect, useState } from "react";
import styles from "./about.module.scss";
import PageTitle from "../../components/PageTitle";
import Team from "../../components/Team";
import Awards from "../../components/Awards";
import { GeneralService } from "../../services";
import { useTranslation } from "react-i18next";
import { Box, Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

export default function About() {
  const { t, i18n } = useTranslation();
  const [aboutUs, setaboutUs] = useState([]);
  const dataId = 43;
  const location = useLocation();

  const inlineStyles = `
  .image{
    width: 45% !important;
  }
  .image-style-side{
    width: 55% !important;
    text-align: center;
    margin: 60px 0 !important;
  }
  .image-style-block-align-left {
    float: right !important;
  }
  @media screen and (max-width: 767px) {
  .image,
  .image-style-side,
  .image-style-block-align-left {
    width: 100% !important;
    float: none !important;
    margin: 0 !important;
  }
}
`;

  useEffect(() => {
    GeneralService.getAll("/GetAll", {
      Type: 8,
      Language: i18n.language === "tr" ? 1 : 0,
    })
      .then((data) => {
        const filteredData = data.DataList.filter(
          (items) => items.Id === dataId
        );
        setaboutUs(filteredData);
      })
      .catch((err) => {
        console.log("Error", err);
        throw err;
      });
  }, [i18n.language]);

  useEffect(() => {
    const elem = document.getElementById(location.hash.slice(1));

    if (elem) {
      elem.scrollIntoView();
    }
  }, [location, aboutUs]);

  return (
    <>
      {aboutUs &&
        aboutUs.map((item) => {
          const textData = JSON.parse(item.Text);
          const { Title, Content, SeoTitle, SeoContent } = textData;

          return (
            <>
              <Helmet>
                <title>{SeoTitle}</title>
                <meta name="description" content={SeoContent} />
              </Helmet>
              <PageTitle Title={Title}></PageTitle>
              <Container>
                <Grid
                  container
                  spacing={{ xs: 0, sm: 1, md: 2 }}
                  columns={12}
                  className={styles.aboutContent}
                >
                  <Box mt={5} className={styles.AboutUs}>
                    <div dangerouslySetInnerHTML={{ __html: Content }} />
                    <style dangerouslySetInnerHTML={{ __html: inlineStyles }} />
                  </Box>
                </Grid>
              </Container>
            </>
          );
        })}
      <div id="team">
        <Team></Team>
      </div>
      <div id="awards">
        <Awards></Awards>
      </div>
    </>
  );
}
