import React from "react";
import Sliders from "../../components/Slider/index";
import About from "./Sections/About";
import Answers from "./Sections/Answers";
import AnswersMobile from "./Sections/Answers/mobile";
import NewMobile from "./Sections/New/mobile";
import Certificate from "./Sections/Certificate";
import New from "./Sections/New";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

function Home() {
  const { t } = useTranslation();
  const isMobile = window.innerWidth <= 768; // Örnek olarak 768px'den küçükse mobil sayılır

  return (
    <>
      <Helmet>
        <title>{t("title")}</title>
        <meta name="description" content={t("desc")} />
      </Helmet>
      <Sliders />
      <About />
      {isMobile ? <AnswersMobile /> : <Answers />}
      <Certificate />
      {isMobile ? <NewMobile /> : <New />}
    </>
  );
}

export default Home;
