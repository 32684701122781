import React, { useEffect, useState, useRef } from "react";
import { Avatar, Grid, Box, IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styles from "./menu.module.scss";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { GeneralService } from "../../services";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import locales from "../../locales";

function Navbar() {
    const [answerPage, setanswerPage] = useState([]);
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { slug } = useParams();
    const [data, setData] = useState([]);

    function getKeyByValue(object, value) {
        return Object.keys(object).find((key) => object[key] === value);
    }

    const menuRef = useRef(null);

    const handleOutsideClick = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        GeneralService.getAll("/GetAll", {
            Type: 8,
            Language: i18n.language === "tr" ? 1 : 0,
        })
            .then((data) => {
                const datalist = data.DataList;
                setanswerPage(datalist);
            })
            .catch((err) => {
                console.log("Error", err);
                throw err;
            });
    }, [i18n.language]);

    useEffect(() => {
        GeneralService.getUrl("/GetAllUrls", {
            Language: i18n.language === "tr" ? 1 : 0,
        })
            .then((result) => {
                const urlList = result.UrlList || [];
                setData(urlList);
            })
            .catch((error) => {
                console.error("Veri çekme hatası:", error);
            });
    }, [i18n.language]);

    const changeLanguage = (lng) => {
        const path = location.pathname.split("/").join(" ").trim().split(" ");
        const arr = path;
        i18n.language === "tr" && arr.push("tr");
        let key = getKeyByValue(
            locales[i18n.language].routes,
            path.slice(0, i18n.language === "tr" ? 1 : 2).join("/")
        );

        if (key) {
            if (arr.length >= 3) {
                const getPath = data.find((e) => e.MainUrl === slug)[
                    lng === "tr" ? "UrlTr" : "UrlEn"
                ];
                const redirect = t(`routes.${key}`, { lng: lng }) + "/" + getPath;
                navigate(redirect);
            } else {
                const redirect = t(`routes.${key}`, { lng: lng });
                navigate(redirect);
            }
        } else {
            i18n.changeLanguage(lng);
        }

        localStorage.setItem("language", lng);
    };

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const [isSubOpen1, setIsSubOpen1] = useState(false);

    const toggleSubMenu1 = () => {
        setIsSubOpen1(!isSubOpen1);
    };

    const [isSubOpen2, setIsSubOpen2] = useState(false);

    const toggleSubMenu2 = () => {
        setIsSubOpen2(!isSubOpen2);
    };

    return (
        <>
            <Grid
                item
                xs={6}
                md={10}
                key={1}
                className={`${styles.navbar} ${isOpen ? styles.openMenu : ""}`}
            >
                <Box sx={{ display: { xs: "none", md: "none" } }}>
                    <ul className={styles.menuBar}>
                        <li className={styles.lang}>
                            {i18n.language === "tr" ? (
                                <div onClick={() => changeLanguage("en")}>
                                    <Avatar
                                        sx={{
                                            width: "16px",
                                            height: "16px",
                                            float: "left",
                                            padding: "0px 5px",
                                        }}
                                        alt="English"
                                        src="/document/images/usa.png"
                                    />
                                    English
                                </div>
                            ) : (
                                <div onClick={() => changeLanguage("tr")}>
                                    <Avatar
                                        sx={{
                                            width: "16px",
                                            height: "16px",
                                            float: "left",
                                            padding: "0px 5px",
                                        }}
                                        alt="Türkçe"
                                        src="/document/images/tr.png"
                                    />
                                    Türkçe
                                </div>
                            )}
                        </li>
                    </ul>
                </Box>

                <IconButton
                    onClick={toggleMenu}
                    sx={{
                        display: {
                            xs: "block",
                            sm: "none",
                            md: "none",
                            lg: "none",
                            xl: "none",
                        },
                    }}
                >
                    <MenuIcon className={styles.menuButton} />
                    <CloseIcon className={styles.closeButton} />
                </IconButton>

                <Box>
                    <ul className={styles.menuBar}>
                        <li
                            key={1}
                            className={`${styles.liMenuBar} ${isSubOpen1 ? styles.openSubMenu : ""
                                }`}
                        >
                            <Link onClick={toggleSubMenu1}>
                                {t("about")}
                                <KeyboardArrowDownIcon
                                    sx={{
                                        fontSize: "medium",
                                        paddingTop: "2px",
                                        paddingLeft: "5px",
                                        float: "right",
                                    }}
                                />
                            </Link>

                            <ul className={styles.subMenu}>
                                <li key={11}>
                                    <Link
                                        onClick={() => {
                                            toggleMenu();
                                            toggleSubMenu1(false);
                                        }}
                                        to={t("routes.about")}
                                    >
                                        {t("aboutUs")}
                                    </Link>
                                </li>
                                <li key={12}>
                                    <Link
                                        onClick={() => {
                                            toggleMenu();
                                            toggleSubMenu1(false);
                                        }}
                                        to={t("routes.team")}
                                    >
                                        {t("teams")}
                                    </Link>
                                </li>
                                <li key={13}>
                                    <Link
                                        onClick={() => {
                                            toggleSubMenu1(false);
                                            toggleMenu();
                                        }}
                                        to={t("routes.affiliates")}
                                    >
                                        {t("affiliates")}
                                    </Link>
                                </li>
                                <li key={14}>
                                    <Link
                                        onClick={() => {
                                            toggleSubMenu1(false);
                                            toggleMenu();
                                        }}
                                        to={t("routes.shareholders")}
                                    >
                                        {t("investors")}
                                    </Link>
                                </li>
                                <li key={16}>
                                    <Link
                                        onClick={() => {
                                            toggleSubMenu1(false);
                                            toggleMenu();
                                        }}
                                        to={t("routes.customers")}
                                    >
                                        {t("customer")}
                                    </Link>
                                </li>
                                <li key={17}>
                                    <Link
                                        onClick={() => {
                                            toggleSubMenu1(false);
                                            toggleMenu();
                                        }}
                                        to={t("routes.representation")}
                                    >
                                        {t("representation")}
                                    </Link>
                                </li>
                                <li key={15}>
                                    <Link
                                        onClick={() => {
                                            toggleSubMenu1(false);
                                            toggleMenu();
                                        }}
                                        to={t("routes.awards")}
                                    >
                                        {t("awards")}
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li
                            key={2}
                            className={`${styles.liMenuBar} ${isSubOpen2 ? styles.openSubMenu : ""
                                }`}
                        >
                            <Link onClick={toggleSubMenu2}>
                                {t("answers")}
                                <KeyboardArrowDownIcon
                                    sx={{
                                        fontSize: "medium",
                                        paddingTop: "2px",
                                        paddingLeft: "5px",
                                        float: "right",
                                    }}
                                />
                            </Link>
                            <ul className={styles.subMenu}>
                                {answerPage.map((item) => {
                                    if (item.Id != 43 && item.Id != 49) {
                                        const textData = JSON.parse(item.Text);
                                        return textData.FooterStatus == undefined ? (
                                            <li key={item.Id}>
                                                <Link
                                                    onClick={() => {
                                                        toggleSubMenu2(false);
                                                        toggleMenu();
                                                    }}
                                                    to={t("routes.answers") + "/" + textData.SeoUrl}
                                                >
                                                    {textData.Title}
                                                </Link>
                                            </li>
                                        ) : null;
                                    } else {
                                        return null;
                                    }
                                })}
                            </ul>
                        </li>
                        <li key={3}>
                            <Link
                                onClick={() => {
                                    toggleMenu();
                                }}
                                to={t("routes.news")}
                            >
                                {t("news")}
                            </Link>
                        </li>
                        <li key={4}>
                            <Link
                                onClick={() => {
                                    toggleMenu();
                                }}
                                to={t("routes.activity")}
                            >
                                {t("activity")}
                            </Link>
                        </li>
                        <li key={5}>
                            <Link
                                onClick={() => {
                                    toggleMenu();
                                }}
                                to={t("routes.contact")}
                            >
                                {t("contact")}
                            </Link>
                        </li>
                        <li className={styles.lang}>
                            {i18n.language === "tr" ? (
                                <div onClick={() => changeLanguage("en")}>
                                    <Avatar
                                        sx={{
                                            width: "16px",
                                            height: "16px",
                                            float: "left",
                                            padding: "0px 5px",
                                        }}
                                        alt="English"
                                        src="/document/images/usa.png"
                                    />
                                    English
                                </div>
                            ) : (
                                <div onClick={() => changeLanguage("tr")}>
                                    <Avatar
                                        sx={{
                                            width: "16px",
                                            height: "16px",
                                            float: "left",
                                            padding: "0px 5px",
                                        }}
                                        alt="Türkçe"
                                        src="/document/images/tr.png"
                                    />
                                    Türkçe
                                </div>
                            )}
                        </li>
                    </ul>
                </Box>
            </Grid>
        </>
    );
}

export default Navbar;