import React, { useEffect, useState } from "react";
import styles from "./slider.module.scss";
import { useTranslation } from "react-i18next";
import { GeneralService } from "../../services";
import Slider from "react-slick";
import "/node_modules/slick-carousel/slick/slick.scss";
import "/node_modules/slick-carousel/slick/slick-theme.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-router-dom";
import config from "../../api/config.json";
import { CircularProgress } from "@mui/material";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className={styles.sliderNext} onClick={onClick}>
      <ArrowForwardIosIcon />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className={styles.sliderPrev} onClick={onClick}>
      <ArrowBackIosIcon />
    </div>
  );
}

function sortSlidersByOrder(sliders) {
  return sliders.slice().sort((a, b) => a.Order - b.Order);
}

export default function Sliders() {
  const { t, i18n } = useTranslation();
  const [sliders, setSliders] = useState([]);
  const [loading, setLoading] = useState(true); // Yükleme durumu için state

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "ease",
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  useEffect(() => {
    setLoading(true); // Yükleme başladığında durumu true yap

    GeneralService.getAll("/GetAll", {
      Type: 7,
      Language: i18n.language === "tr" ? 1 : 0,
    }).then((data) => {
      const datalist = data.DataList;
      const sortedSliders = sortSlidersByOrder(datalist);
      setSliders(sortedSliders);
      setLoading(false); // Yükleme tamamlandığında durumu false yap
    });
  }, [i18n.language]);

  return (
    <>
      {loading && (
        <div className={styles.loadingContainer}>
          <CircularProgress />
        </div>
      )}
      <div className={styles.slider}>
        {!loading && sliders && sliders.length > 0 && (
          <Slider {...settings}>
            {sliders.map((items, key) => {
              const textData = JSON.parse(items.Text);
              const { Url, Title, Image } = textData;
              return (
                <Link key={key} to={Url}>
                  <img src={config[0].URL + Image} alt={Title} />
                </Link>
              );
            })}
          </Slider>
        )}
      </div>
    </>
  );
}
