import { axiosInstance } from "../../api/axiosinstance";

export const HomeService = {
  getAbout: async (url, payload) => {
    let responseData = [];
    await axiosInstance
      .post(url, payload)
      .then((res) => {
        responseData = res.data;
      })
      .catch((err) => {
        console.log("ERROR", err);
        throw err;
      });
    return responseData;
  },

  getNews: async (url, payload) => {
    let responseData = [];

    await axiosInstance
      .post(url, payload)
      .then((res) => {
        responseData = res.data;
      })
      .catch((err) => {
        console.log("ERROR", err);
        throw err;
        //throw "exeption...";
        // throw {
        //   err: err,
        // };
      });
    return responseData;
  },
  postContact: async (url, payload) => {
    let responseData = [];
    await axiosInstance
      .post(url, payload)
      .then((res) => {
        responseData = res.data;
      })
      .catch((err) => {
        console.error("İletişim Formu Hata", err);
        throw err;
      });
    return responseData;
  },
};
