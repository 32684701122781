import locales from "../locales";
import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../layout";
import Home from "../pages/Home";
import About from "../pages/About";
import Customers from "../pages/Customers";
import Representation from "../pages/Representation";
import AffiliatesDetail from "../pages/Affiliates/detail";
import Affiliates from "../pages/Affiliates";
import Shareholders from "../pages/Shareholders";
import Answers from "../pages/Answers";
import TeamDetail from "../pages/TeamDetail";
import NewsDetail from "../pages/News/detail";
import Activity from "../pages/Activity";
import News from "../pages/News";
import Contact from "../pages/Contact";
import StandartPage from "../pages/StandartPage";
import Softpos from "../pages/Softpos";
import SoftposValidate from '../pages/SoftposValidate';

function Router() {
    const { i18n } = useTranslation();
    const location = useLocation();

    useEffect(() => {
        const lang = location.pathname.split(/\/([a-z]{2})(?![^/])/gm)[1];
        if (!lang) i18n.changeLanguage("tr");
        const i = Object.keys(locales).findIndex((f) => f === lang);
        if (i !== -1 && i18n.language !== lang) {
            i18n.changeLanguage(lang);
        }
    }, [location, i18n]);

    return (
        <Routes>
            <Route element={<Layout />}>
                <Route path={"en"}>
                    <Route index element={<Home />} />
                    <Route path="about" element={<About />} />
                    <Route path="customers" element={<Customers />} />
                    <Route path="representation" element={<Representation />} />
                    <Route path="subsidiaries/:slug" element={<AffiliatesDetail />} />
                    <Route path="subsidiaries" element={<Affiliates />} />
                    <Route path="shareholders" element={<Shareholders />} />
                    <Route path="solutions/:slug" element={<Answers />} />
                    <Route path="teams/:slug" element={<TeamDetail />} />
                    <Route path="news/:slug" element={<NewsDetail />} />
                    <Route path="events" element={<Activity />} />
                    <Route path="news" element={<News />} />
                    <Route path="contact" element={<Contact />} />
                    <Route path="link/:slug" element={<StandartPage />} />                    
                </Route>
                <Route index element={<Home />} />
                <Route path="biz-kimiz" element={<About />} />
                <Route path="musterilerimiz" element={<Customers />} />
                <Route path="temsilciliklerimiz" element={<Representation />} />
                <Route path="istirakler/:slug" element={<AffiliatesDetail />} />
                <Route path="istirakler" element={<Affiliates />} />
                <Route path="yatirimcilar" element={<Shareholders />} />
                <Route path="cozumler/:slug" element={<Answers />} />
                <Route path="ekibimiz/:slug" element={<TeamDetail />} />
                <Route path="haberler/:slug" element={<NewsDetail />} />
                <Route path="etkinlikler" element={<Activity />} />
                <Route path="haberler" element={<News />} />
                <Route path="iletisim" element={<Contact />} />
                <Route path="link/:slug" element={<StandartPage />} />
                <Route path="*" element={<div>404</div>} />
            </Route>
            <Route path={"en"}>
                <Route path="softpos-application-validation" element={<SoftposValidate />} />
            </Route>
            <Route path="softpos-uygulama-dogrulama" element={<SoftposValidate />} />
            <Route path="softpos/status" element={<Softpos />} />
        </Routes>
    );
}

export default Router;