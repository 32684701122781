import {
  IconButton,
  Container,
  Grid,
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  CardMedia,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import EastIcon from "@mui/icons-material/East";
import styles from "./new.module.scss";
import { HomeService } from "../../../../services";
import { Link } from "react-router-dom";
import config from "../../../../api/config.json";
import { useTranslation } from "react-i18next";

function New(props) {
  const [news, setNews] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    HomeService.getAbout("/GetAll", {
      Type: 0,
      Language: i18n.language === "tr" ? 1 : 0,
    })
      .then((data) => {
        const datalist = data.DataList;
        datalist.sort((a, b) => {
          const rowNumberA = JSON.parse(a.Text).RowNumber;
          const rowNumberB = JSON.parse(b.Text).RowNumber;
          return rowNumberB - rowNumberA;
        });
        const top4News = datalist.slice(0, 4);
        setNews(top4News);
      })
      .catch((err) => {
        console.log("Error", err);
        throw err;
      });
  }, [i18n.language]);
  const { title, subTitle } = props;

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Grid columnSpacing={{ md: 12 }} className={styles.newHome}>
        <Container>
          <Typography variant="span">{subTitle}</Typography>
          <Typography variant="h2">{title || t("news")}</Typography>

          <Box sx={{ flexGrow: 1 }}>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
              {news &&
                news.map((item, key) => {
                  const textData = JSON.parse(item.Text);
                  const { SeoUrl, Title, Date, Content, Image } = textData;

                  return (
                    <>
                      <Grid
                        xs={4}
                        sm={4}
                        md={3}
                        className={styles.newList}
                        key={key}
                      >
                        <Link
                          to={`/${t("routes.news")}/${SeoUrl}`}
                          key={key}
                          onClick={scrollTop}
                        >
                          <Card columns={{ md: 12 }}>
                            <CardMedia
                              sx={{ height: 210 }}
                              image={config[0].URL + `${Image}`}
                            />
                            <CardContent className={styles.cardContent}>
                              <Typography gutterBottom variant="caption">
                                {Date}
                              </Typography>
                              <Typography gutterBottom variant="h5">
                                {Title}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: Content,
                                  }}
                                />
                              </Typography>
                            </CardContent>
                            <CardActions>
                              <IconButton
                                className={styles.iconButton}
                                component="label"
                              >
                                <EastIcon />
                              </IconButton>
                            </CardActions>
                          </Card>
                        </Link>
                      </Grid>
                    </>
                  );
                })}
            </Grid>
          </Box>
        </Container>
      </Grid>
    </>
  );
}

export default New;
