import {
  Container,
  Grid,
  Typography,
  CardMedia,
  Card,
  CardContent,
  Link,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./team.module.scss";
import { useTranslation } from "react-i18next";
import { GeneralService } from "../../services";
import config from "../../api/config.json";

export default function Team() {
  const { t, i18n } = useTranslation();
  const [teams, setteams] = useState([]);

  useEffect(() => {
    GeneralService.getAll("/GetAll", {
      Type: 6,
      Language: i18n.language === "tr" ? 1 : 0,
    }).then((data) => {
      const datalist = data.DataList;

      // Sıralama işlemi
      datalist.sort((a, b) => {
        const rowNumberA = JSON.parse(a.Text).RowNumber;
        const rowNumberB = JSON.parse(b.Text).RowNumber;
        return rowNumberA - rowNumberB;
      });
      setteams(datalist);
    });
  }, [i18n.language]);
  return (
    <>
      <Container className={styles.team}>
        <Typography textAlign={"center"} mt={5} mb={5} variant="h2">
          {t("teams")}
        </Typography>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          columns={12}
        >
          {teams &&
            teams.map((items) => {
              const textData = JSON.parse(items.Text);
              const { SeoUrl, Title, Position, Image } = textData;
              return (
                <>
                  <Grid
                    xs={6}
                    sm={6}
                    md={4}
                    key={items.Id}
                    textAlign={"center"}
                  >
                    <Card variant="outlined" className={styles.card}>
                      <Link
                        href={"/" + t("routes.teamDetail") + "/" + SeoUrl}
                        className={styles.link}
                      >
                        <CardMedia
                          component="img"
                          image={config[0].URL + `${Image}`}
                          alt={Title}
                        />
                        <CardContent>
                          <Typography variant="h6">{Title}</Typography>
                          <Typography variant="span">{Position}</Typography>
                        </CardContent>
                      </Link>
                    </Card>
                  </Grid>
                </>
              );
            })}
        </Grid>
      </Container>
    </>
  );
}
