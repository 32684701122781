import React from "react";
import Header from "./header";
import Footer from "./footer";
import { Outlet } from "react-router-dom";
import ScrollToTop from "../components/ScroolTop";

function Layout({ children }) {
    
    return (
        <>
            <ScrollToTop />
            <Header />
            {children}
            <Outlet></Outlet>
            <Footer />
        </>
    );
}

export default Layout;
