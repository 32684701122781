import {
  Container,
  Grid,
  Box,
  Avatar,
  Typography,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EastIcon from "@mui/icons-material/East";
import styles from "./answers.module.scss";
import { Link } from "react-router-dom";
import { GeneralService } from "../../../../services";
import { useTranslation } from "react-i18next";

function Answers() {
  const { t, i18n } = useTranslation();
  const [answers, setanswers] = useState([]);

  useEffect(() => {
    GeneralService.getAll("/GetAll", {
      Type: 8,
      Language: i18n.language === "tr" ? 1 : 0,
    })
      .then((data) => {
        const datalist = data.DataList;
        setanswers(datalist);
      })
      .catch((err) => {
        console.log("Error", err);
        throw err;
      });
  }, [i18n.language]);

  return (
    <>
      <Grid columnSpacing={{ md: 12 }} className={styles.answerHome}>
        <Container>
          <Typography></Typography>
          <Typography variant="h2"> {t("answers")}</Typography>

          <Box sx={{ flexGrow: 1 }}>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
              {answers &&
                answers.slice(-6).map((item, key) => {
                  const TextData = JSON.parse(item.Text);
                  const { SeoUrl, Title } = TextData;
                  return (
                    <Link to={`/${t("routes.answers")}/${SeoUrl}`} key={key}>
                      <Grid xs={4} sm={4} md={4} className={styles.answerList}>
                        <Box className={styles.answers} columns={{ md: 12 }}>
                          <Avatar
                            sx={{ height: 65, width: 65 }}
                            src={`/document/images/icons/${key}.svg`}
                            variant="rounded"
                          ></Avatar>
                          <Typography variant="h3">{Title}</Typography>
                          <IconButton aria-label="Example">
                            <EastIcon />
                          </IconButton>
                        </Box>
                      </Grid>
                    </Link>
                  );
                })}
            </Grid>
          </Box>
        </Container>
      </Grid>
    </>
  );
}

export default Answers;
