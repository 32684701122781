import React, { useState, useEffect } from "react";
import PageContent from "../../components/PageContent";
import {
    Container,
    Grid,
    Card,
    CardContent,
    Typography,
    Box,
    SvgIcon,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GeneralService } from "../../services";

export default function Answers() {
    const [answers, setanswers] = useState([]);
    const { t, i18n } = useTranslation();
    const { slug } = useParams();
    const [pageTitle, setPageTitle] = useState("");

    useEffect(() => {
        GeneralService.getAll("/GetAll", {
            Type: 8,
            Language: i18n.language === "tr" ? 1 : 0,
        })
            .then((data) => {
                const datalist = data.DataList;
                setanswers(datalist);

                // Sayfa başlığını belirle
                const currentPage = datalist.find((item) => {
                    const textData = JSON.parse(item.Text);
                    return textData.SeoUrl === slug;
                });

                if (currentPage) {
                    const textData = JSON.parse(currentPage.Text);
                    setPageTitle(textData.Title);
                }
            })
            .catch((err) => {
                console.log("Error", err);
                throw err;
            });
    }, [i18n.language, slug]);

    // SVG ikonlar için bir bileşen oluşturuyoruz
    function CustomIcon(props) {
        return (
            <SvgIcon {...props}>
                <image href={props.href} width="100%" height="100%" />
            </SvgIcon>
        );
    }

    return (
            <Container>
                {answers &&
                    answers.map((item) => {
                        const textData = JSON.parse(item.Text);
                        const { Title, Content, Image, SeoTitle, SeoContent } = textData;

                        if (textData.SeoUrl === slug) {
                            return (
                                <PageContent
                                    key={1}
                                    Title={Title}
                                    Content={Content}
                                    Image={Image}
                                    SeoContent={SeoContent}
                                    SeoTitle={SeoTitle}
                                />
                            );
                        }
                    })}

                {(slug === "softpos-cozumleri" || slug === "softpos-solutions") && (
                    <Grid container spacing={3} mt={4}>
                        <Grid item xs={12} sm={4}>
                            <Card
                                component="a"
                                href="mailto:HelpDesk@dgpays.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{
                                    height: "80%",
                                    backgroundColor: "#f0f4ff",
                                    borderRadius: "10px",
                                    boxShadow: "none",
                                    padding: "20px",
                                    display: "inline-block",
                                    '&:hover': {
                                        backgroundColor: "#e0e7ff",
                                        transition: "background-color 0.3s ease",
                                    },
                                }}
                            >
                                <CardContent>
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        textAlign="left"
                                        mb={2}
                                    >
                                        <CustomIcon
                                            href="/document/email.svg"
                                            style={{ fontSize: 40 }}
                                        />
                                    </Box>
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        align="left"
                                        style={{ fontWeight: "bold", color: "#000000" }}
                                    >
                                        {t("answer.EpostaTitle")}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        align="left"
                                        fontSize={"1.2rem"}
                                        style={{ color: "#5A6978" }}
                                    >
                                        {t("answer.EpostaText")}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Card
                                component="a"
                                href="https://selfservice.dgpays.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{
                                    height: "80%",
                                    backgroundColor: "#f0f4ff",
                                    borderRadius: "10px",
                                    boxShadow: "none",
                                    padding: "20px",
                                    display: "inline-block",
                                    '&:hover': {
                                        backgroundColor: "#e0e7ff",
                                        transition: "background-color 0.3s ease",
                                    },
                                }}
                            >
                                <CardContent>
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        textAlign="left"
                                        mb={2}
                                    >
                                        <CustomIcon
                                            href="/document/ticet.svg"
                                            style={{ fontSize: 40 }}
                                        />
                                    </Box>
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        align="left"
                                        style={{ fontWeight: "bold", color: "#000000" }}
                                    >
                                        {t("answer.DestekTitle")}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        align="left"
                                        fontSize={"1.2rem"}
                                        style={{ color: "#5A6978" }}
                                    >
                                        {t("answer.DestekText")}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Card
                                component="a"
                            href="/softpos-uygulama-dogrulama"
                            target="_blank"
                                rel="noopener noreferrer"
                                sx={{
                                    height: "80%",
                                    backgroundColor: "#f0f4ff",
                                    borderRadius: "10px",
                                    boxShadow: "none",
                                    padding: "20px",
                                    display: "inline-block",
                                    '&:hover': {
                                        backgroundColor: "#e0e7ff",
                                        transition: "background-color 0.3s ease",
                                    },
                                }}
                            >
                                <CardContent>
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        textAlign="left"
                                        mb={2}
                                    >
                                        <CustomIcon
                                            href="/document/pin.svg"
                                            style={{ fontSize: 40 }}
                                        />
                                    </Box>
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        align="left"
                                        style={{ fontWeight: "bold", color: "#000000" }}
                                    >
                                        {t("answer.UygulamaTitle")}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        align="left"
                                        fontSize={"1.2rem"}
                                        style={{ color: "#5A6978" }}
                                    >
                                        {t("answer.UygulamaText")}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                )}
            </Container>        
    );
}
