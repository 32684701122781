import { Grid } from "@mui/material";
import React from "react";
import styles from "./pageTitle.module.scss";

export default function PageTitle(props) {
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={styles.title}
      >
        <h1>{props.Title}</h1>
      </Grid>
    </>
  );
}
