import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Box,
  Avatar,
  Typography,
  IconButton,
} from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import styles from "./answers.module.scss";
import { Link } from "react-router-dom";
import { GeneralService } from "../../../../services";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function AnswersMobile() {
  const { t, i18n } = useTranslation();
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    GeneralService.getAll("/GetAll", {
      Type: 8,
      Language: i18n.language === "tr" ? 1 : 0,
    })
      .then((data) => {
        const dataList = data.DataList;
        setAnswers(dataList);
      })
      .catch((err) => {
        console.error("Error", err);
        throw err;
      });
  }, [i18n.language]);
  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div className={styles.sliderNext} onClick={onClick}>
        <ArrowForwardIosIcon />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div className={styles.sliderPrev} onClick={onClick}>
        <ArrowBackIosIcon />
      </div>
    );
  }
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <>
      <Grid columnSpacing={{ md: 12 }} className={styles.answerHome}>
        <Container>
          <Typography></Typography>
          <Typography variant="h2"> {t("answers")}</Typography>

          <Box sx={{ flexGrow: 1 }} className={styles.sliderMobile}>
            <Slider {...settings}>
              {answers &&
                answers.slice(-6).map((item, key) => {
                  const textData = JSON.parse(item.Text);
                  const { SeoUrl, Title } = textData;
                  return (
                    <Link to={`/${t("routes.answers")}/${SeoUrl}`} key={key}>
                      <Grid xs={4} sm={4} md={4} className={styles.answerList}>
                        <Box className={styles.answers} columns={{ md: 12 }}>
                          <Avatar
                            sx={{ height: 65, width: 65 }}
                            src={`/document/images/icons/${key}.svg`}
                            variant="rounded"
                          ></Avatar>
                          <Typography variant="h3">{Title}</Typography>
                          <IconButton aria-label="Example">
                            <EastIcon />
                          </IconButton>
                        </Box>
                      </Grid>
                    </Link>
                  );
                })}
            </Slider>
          </Box>
        </Container>
      </Grid>
    </>
  );
}

export default AnswersMobile;
