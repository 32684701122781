import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  Snackbar,
  Alert,
} from "@mui/material";
import styles from "./contactForm.module.scss";
import { useTranslation } from "react-i18next";
import { HomeService } from "../../services";

export default function ContactForm({ open, onClose }) {
  const { t, i18n } = useTranslation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [randomNum1, setRandomNum1] = useState(0);
  const [randomNum2, setRandomNum2] = useState(0);
  const [userInput, setUserInput] = useState("");

  const handleRandomSum = () => {
    const num1 = Math.floor(Math.random() * 10) + 1;
    const num2 = Math.floor(Math.random() * 10) + 1;

    setRandomNum1(num1);
    setRandomNum2(num2);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleUserInputChange = (event) => {
    setUserInput(event.target.value);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    handleRandomSum();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const userEnteredSum = parseInt(userInput, 10);

    if (userEnteredSum !== randomNum1 + randomNum2) {
      showSnackbar("Güvenlik kontrolü başarısız!", "error");
      return;
    }

    const AdressName = event.target.elements.AdressName.value.trim();
    const Email = event.target.elements.Email.value.trim();
    const ShortName = event.target.elements.ShortName.value.trim();
    const Adress = event.target.elements.Adress.value.trim();

    // Message
    const successMessage = t("contactForm.successMessage");
    const errorMessage = t("contactForm.errorMessage");
    const nameControlFailedMessage = t("contactForm.nameControlFailedMessage");
    const lenghtLimitErrorMessage = t("contactForm.lenghtLimitErrorMessage");
    const emailErrorMessage = t("contactForm.emailErrorMessage");

    if (
      !AdressName ||
      !AdressName.match(
        /^([a-zA-ZğüşöçıİĞÜŞÖÇ]{1,}\s[a-zA-ZğüşöçıİĞÜŞÖÇ]{1,}'?-?[a-zA-ZğüşöçıİĞÜŞÖÇ]{1,}\s?([a-zA-ZğüşöçıİĞÜŞÖÇ]{1,})?)/
      )
    ) {
      showSnackbar(nameControlFailedMessage, "error");
      return;
    }

    if (!/^.+@.+(\..{2,})+$/.test(Email)) {
      showSnackbar(emailErrorMessage, "error");
      return;
    }

    if (
      AdressName.length > 200 ||
      Email.length > 200 ||
      ShortName.length > 200 ||
      Adress.length > 1000
    ) {
      showSnackbar(lenghtLimitErrorMessage, "error");
      return;
    }

    event.target.elements.AdressName.value = "";
    event.target.elements.Email.value = "";
    event.target.elements.ShortName.value = "";
    event.target.elements.Adress.value = "";
    event.target.elements.randomSum.value = "";

    HomeService.postContact("/AddContact", {
      AdressName,
      Email,
      ShortName,
      Adress,
    })
      .then((data) => {
        showSnackbar(successMessage, "success");
        event.target.reset();
        handleRandomSum();
        setUserInput("");
      })
      .catch((err) => {
        console.log("Error", err);
        showSnackbar(errorMessage, "error");
      });
    event.target.reset();
    handleRandomSum();
  };

  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#E6EBF0",
              },
            },
          },
        },
      },
    },
  });

  return (
    <Container>
      <Box xs={6} md={6} className={styles.formModal}>
        <Card className={styles.card}>
          <Typography variant="h4">{t("contactForm.title")}</Typography>
          <CardContent className={styles.formGrid}>
            <ThemeProvider theme={theme}>
              <form onSubmit={handleSubmit}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label={t("contactForm.name")}
                  id="AdressName"
                  variant="outlined"
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label={t("contactForm.eposta")}
                  id="Email"
                  variant="outlined"
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label={t("contactForm.companyName")}
                  id="ShortName"
                  variant="outlined"
                />
                <TextField
                  label={t("contactForm.message")}
                  fullWidth
                  required
                  margin="normal"
                  multiline
                  rows={4}
                  id="Adress"
                  variant="outlined"
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label={`(${randomNum1} + ${randomNum2}) =`}
                  id="randomSum"
                  variant="outlined"
                  onFocus={handleRandomSum}
                  value={userInput}
                  onChange={handleUserInputChange}
                />
                <Button
                  className={styles.buttons}
                  variant="outlined"
                  type="submit"
                >
                  {t("contactForm.btnName")}
                </Button>
              </form>
            </ThemeProvider>
          </CardContent>
        </Card>
      </Box>
      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}
